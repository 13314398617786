<template>
   <div class="tougao">
      <i-header></i-header>
      <div class="contribute scrol">
         <h4><img src="../assets/images/gywm.png">关于我们</h4>
         <div class="manner">
            <div>
               <p>
                  《中华传统艺术精品百科资源库（中小学美育版）》是“十四五”时期国家重点出版物出版专项规划项目，旨在提供美育知识服务、传播中华优秀传统文化。平台以《中国大百科全书》《中国美术百科全书》等为基础，收录雕塑、绘画、书法、建筑、篆刻、美术家、工艺美术、民间美术、音乐名家、乐器、古曲、现当代音乐、音乐常识等数十个中华优秀传统艺术门类，包含图片、文字、视频，动画、VR等多形态内容。
               </p>
               <p>
                  平台由美术、音乐学科领域权威专家及中央教育科学院的专家顾问组成的编委会，承担过多个国家级项目、经验丰富的编创团队，以及首都师范大学美育研究中心、出版业科技与标准重点实验室等指导单位共同组成的编创团队倾力打造。
               </p>
               <p>
                  平台中的内容仅供用户个人学习、研究欣赏及著作权法规定的方式合理使用，任何用户只能在本平台授权下合理使用平台内容及服务，不能擅自复制、再造、或创造与本平台内容有关的派生产品等，或以其他任何形式侵犯著作权人的权利，中国大百科全书出版社有限公司仅对用户在上述使用范围内的合法行为负责。中国大百科全书出版社有限公司拥有最终解释权，并保留依法追究各种侵权行为的权利。
               </p>
            </div>
            <h3>《中华传统艺术精品百科资源库（中小学美育版）》主要编创出版人员</h3>
            <div>
                  <label>
                     主　　编：<span>编委会</span></br>
                     指导单位：<span>首都师范大学美育研究中心</span></br>
                     责任编辑：<span>谢   雪</span> </br>
                     美术编辑：<span>谢   雪</span><span>李   红</span><span>李露娟</span> </br>
                     技术编辑：<span>熊秀鑫</span><span>刘天荣</span></br>
                     编　　辑：<span>黄  磊</span><span>吴  泱</span><span>韩瑞彬</span><span>李  琰</span><span>马烨华</span><span>屈新颖</span><span>李   红</span><span>李露娟</span><span>万卓成</span></br>
                     技术制作：<span>北京百科在线网络出版有限公司</span></br>
                  </label>
               </p>
            </div>
            <div>
               <label>
                   <p style="display: flex;align-items: center;padding-bottom: 10px"><img src="../assets/images/dbklogo.png" style="width: 500px">出版</p>
                  I&nbsp S&nbsp B&nbsp N：978-7-900179-86-9</br>
                  地　　址：北京市阜成门北大街17号</br>
                  邮　　编：100037</br>
                  电　　话：010-88390682</br>
                  网　　址：<a href="http://www.ecph.com.cn" target="_blank">http://www.ecph.com.cn</a></br>
                  出版时间:2022年6月</br>
                  中国大百科全书出版社版权所有，侵权必究。
               </label>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
  import iHeader from '@/components/Header.vue';
  export default {
     name: "aboutUs",
     components: {
        iHeader,
     },
    data(){
      return{

      }
    }
  }
</script>
<style scoped>
.tougao{
   min-height: 100%;
   background-image: url("../assets/images/details/bg.png");
   background-size: 100% 100%;
}
.tougao >>> .menu_bar{
   display: none;
}
.contribute{
   width: 900px;
   margin: auto;
   background: rgba(255,255,255,.75);
   height: 100vh;
   padding: 70px 60px;
   box-sizing: border-box;
   text-align: left;
   overflow-y: auto;
}
.contribute h4{
   font-size: 30px;
   color: #333333;
   padding-bottom: 25px;
   border-bottom: 1px solid #B3B3B3;
}
.contribute h4 img{
   width: 30px;
   height: 30px;
   margin-right: 12px;
   vertical-align: text-bottom;
   margin-bottom: 3px;
}
.contribute h3{
   font-size: 24px;
   font-weight: 500;
   text-align: center;
   padding-bottom: 15px;
   border-bottom: 1px dashed #B3B3B3;
   margin-bottom: 20px;
}
.manner{
   color: #333333;
   font-size: 16px;
   line-height: 32px;
   padding-top: 30px;
}
.manner div{
   margin-bottom: 30px;
}
.manner p{
   text-indent: 32px;
}
.manner label span{
   margin-right: 30px;
}
.manner label a{
   text-decoration: none;
   color: #0000EE;
}
</style>
